import { Grid, Typography } from "@mui/material";
import React from "react";
import WorkTimeline from "./timeline.tsx";
import { Skills } from "../../utils/skills.tsx";
import mainTheme from "../../theme/main-theme.tsx";

const WorkExperience = () => {
  return (
    <Grid
      sx={{ flexWrap: "wrap", width: "auto", justifyContent: "flex-start" }}
      margin={3}
      container
    >
      <Grid xs={12} item>
        <Typography
          padding={0}
          color={mainTheme.palette.text.primary}
          fontSize={50}
        >
          Work Experience
        </Typography>
      </Grid>
      <Grid xs={12} md={6} item>
        <WorkTimeline />
      </Grid>
      <Grid xs={12} md={6} item>
        <Grid container>
          <Grid
            sx={{
              backgroundColor: mainTheme.palette.background.infoBoxes,
              borderRadius: "20px",
              padding: "30px",
              marginBottom: "25px",
            }}
            xs={12}
            item
          >
            <Typography
              sx={{
                [mainTheme.breakpoints.down("md")]: {
                  fontSize: "16px"
                },
              }}
              color="white"
            >
              My academic pursuits and professional experiences have ignited a
              deep passion for web development. Through a combination of
              dedicated coursework, hands-on internships, and independent
              projects, I've actively expanded my skillset to encompass a
              diverse range of technologies. I'm proficient in JavaScript
              frameworks like React and Next.js, allowing me to craft dynamic
              and performant user interfaces. Additionally, my experience with
              Node.js empowers me to build robust server-side applications.
              Furthermore, Python and TypeScript have equipped me with strong
              back-end and front-end development capabilities, respectively. To
              complement my technical expertise, I've delved into the realms of
              UI/UX design principles, enabling me to create user-centric
              interfaces that prioritize both functionality and aesthetics. This
              blend of technical and design skills allows me to approach web
              development projects holistically, ensuring an exceptional user
              experience.
            </Typography>
          </Grid>
          <Grid
            sx={{
              backgroundColor: mainTheme.palette.background.infoBoxes,
              borderRadius: "20px",
              padding: "30px",
            }}
            xs={12}
            item
          >
            {Skills().map((skill, key) => (
              <Typography
                key={key}
                color={mainTheme.palette.text.primary}
                sx={{
                  border: `1px solid ${mainTheme.palette.background.button}`,
                  borderRadius: "10px",
                  padding: "10px",
                  display: "inline-block",
                  margin: "5px",
                }}
              >
                {skill}
              </Typography>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WorkExperience;
