import React from "react";
import { getProjects } from "../../utils/projects.tsx";
import { Grid, Link, Typography } from "@mui/material";
import mainTheme from "../../theme/main-theme.tsx";

const Projects = () => {
  return (
    <Grid margin={3} container>
      <Grid color="white" fontSize={50} xs={12} item>
        Projects
      </Grid>
      {getProjects().map((project, key) => (
        <Link underline="none" key={key} href={project.url}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "20px",
              padding: "10px",
              marginTop: "20px",
              marginRight: "20px",
              backgroundColor: mainTheme.palette.background.infoBoxes,
              flexWrap: "wrap",
              minWidth: "250px",
              minHeight: "250px",
              maxWidth: "250px",
              transition:
                "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: 'rgba(100, 100, 100, 0.9)',
                color: "black",
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.2)", // Add subtle shadow
                transform: "scale(1.05)", // Move slightly up on hover
              },
            }}
            item
          >
            <Typography
              maxWidth="100%"
              align="center"
              fontSize={30}
              color="white"
            >
              {project.title}
            </Typography>
          </Grid>
        </Link>
      ))}
    </Grid>
  );
};

export default Projects;
