import React from "react";
import myImage from "../../pictures/myimage.png";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import mainTheme from "../../theme/main-theme.tsx";

const CoverImage = () => {
  const isSmallScreen = useMediaQuery("(max-width:899px)");
  return (
    <Box sx={{ width: "100%" }}>
      <Grid sx={{}} alignItems="center" container>
        {!isSmallScreen && (
          <Grid
            padding={3}
            color={mainTheme.palette.text.primary}
            height="70%"
            xs={6}
            fontSize={30}
            item
          >
            <p>
              Hey there, <br /> I'm John!
            </p>
            <p>In Pursuit of Full Stack Mastery</p>
          </Grid>
        )}
        <Grid
          sx={{
            top: "10px",
          }}
          height="auto"
          xs={12}
          md={6}
          item
        >
          <img className="myImage" src={myImage} alt="myImage" />
        </Grid>
        <Grid
          sx={{
            backgroundColor: "black",
            color: mainTheme.palette.text.primary,
            height: "100%",
            padding: "100px",
            [mainTheme.breakpoints.down("md")]: {
              padding: '50px',
            }
          }}
          padding={3}
          justifyContent="center"
          alignItems="center"
          xs={12}
          item
        >
          <Typography sx={{
            fontSize: '30px',
            [mainTheme.breakpoints.down("md")]: {
              fontSize: '16px'
            }
          }} textAlign="center" fontSize={30}>
            I'm on a mission to create software that's stable, elegant and
            effortlessly effective
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CoverImage;
