import { createTheme } from "@mui/material";

const mainTheme = createTheme({
    typography: {
        fontFamily: "Space Grotesk, sans-serif"
    },
    palette: {
        text:{
            primary: "#FFFFFF",
            secondary: "#808080"
        },
        background: {
            default: "#292929",
            buttonBoxes: "rgba(255, 255, 255, 0.1)",
            infoBoxes: "#464646",
            buttonHover: "#070707",
            button: "#606060"
        }

    },
})

export default mainTheme;