import React from "react";
import Timeline from "@mui/lab/Timeline";
import {

  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { Typography } from "@mui/material";
import { Experience } from "../../types/experience";
import mainTheme from "../../theme/main-theme.tsx";

const getWorkExperience = (): Experience[] => {
  return [
    {
      position: "Werkstudent Full Stack Webentwickler",
      company: "VIIND GmbH",
      dateRange: "Nov. 2022 - Heute",
      description: "Expansion of an AI-based multi-platform chatbot application consisting of frontend (React, TypeScript, NextJS, React Native) and backend (NestJS, Python)",
    },
    {
      position: "Refurbishment und Qualitätssicherung",
      company: "Anfema GmbH",
      dateRange: "Nov. 2019 - Sep. 2020",
      description: `Quality assurance, refurbishment and firmware installations`,
    },
    {
      position: "Praktikum IT Anwendungsentwickler",
      company: "Sewobe AG",
      dateRange: "Aug. 2017 ",
      description: "Data preparation, Working through and analysing SQL commands, Converting SQL commands to TYPO 3 'tx_new' and Testing",
    },
  ];
};

const WorkTimeline = () => {
  return (
    <Timeline sx={{justifyContent: "flex-start"}}>
      {getWorkExperience().map((experience, key) => (
        <TimelineItem key={key}>
          <TimelineOppositeContent sx={{pt: "27px", flex:0.3}}>
            <Typography color="white">{experience.dateRange}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot></TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ width: '100%', py: "12px", px:"15px", flex:0.7}} justifyContent="center" >
            <Typography variant="h6" fontWeight="bold" color={mainTheme.palette.text.primary}>{experience.position}</Typography>
            <Typography fontSize={15} color={mainTheme.palette.text.secondary}>{experience.description}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default WorkTimeline;
