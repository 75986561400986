import { Project } from "../types/projects.ts"


export const getProjects = (): Project[] => {
    return [
        {
            title: "Spirograph",
            url: "https://spirograph.vercel.app"
        },
        {
            title: "TJM.Tech Design",
            url:"https://www.figma.com/file/M45xWktKOySRjSNMC3lWFX/Landing-Page?type=design&node-id=92%3A1202&mode=design&t=Qs8qGmSkedFErvZ6-1"
        },
        {
            title: "HSA Campus App Design",
            url: "https://tha.incom.org/project/5155"
        },
        {
            title: "Coming Soon!",
            url: ""
        }
    ]
}