import React, { useRef } from "react";
import NavBar from "../Components/nav-bar/nav-bar.tsx";
import { ThemeProvider} from "@emotion/react";
import { Paper,} from "@mui/material";
import CoverImage from "../Components/cover-image.tsx/cover-image.tsx";
import AboutMe from "../Components/about-me/aboutme.tsx";
import WorkExperience from "../Components/work-experience/work-experience.tsx";
import Projects from "../Components/projects/projects.tsx";
import mainTheme from "../theme/main-theme.tsx";

const LandingPage = () => {
  const AboutRef = useRef(null);
  const WorkRef = useRef(null);
  const ProjectsRef = useRef(null);

  const handleButtonClick = (target: string) => {
    switch (target) {
      case "About":
        AboutRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Work":
        WorkRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "Projects":
        ProjectsRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  };
  return (
    <ThemeProvider theme={mainTheme}>
      <Paper
        sx={{
          backgroundColor: mainTheme.palette.background.default,
          height: "100%",
          minHeight: "100%",
          overflow: "auto",
        }}
      >
        <NavBar
          handleButtonClick={handleButtonClick}
        />
        <CoverImage />
        <div ref={AboutRef}>
          <AboutMe />
        </div>
        <div ref={WorkRef}>
          <WorkExperience />
        </div>
        <div ref={ProjectsRef}>
          <Projects />
        </div>
      </Paper>
    </ThemeProvider>
  );
};

export default LandingPage;
