
export const Skills = () => {
    return [
        "HTML",
        "CSS",
        "JavaScript",
        "P5.js",
        "React",
        "Python",
        "PHP",
        "GraphQL",
        "JQuery",
        "VUE.js",
        "Git",
        "TypeScript",
        "NodeJS / NextJS / NestJS",
        "Scrum",
        "REST",
        "React Native",
    ]
}