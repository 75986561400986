import React from "react";
import { Grid, Typography } from "@mui/material";
import mainTheme from "../../theme/main-theme.tsx";

const AboutMe = () => {
  return (
      <Grid sx={{ flexWrap: 'wrap', width: 'auto'}} margin={3} container>
        <Grid xs={12} item>
          <Typography
            fontFamily={mainTheme.typography.fontFamily}
            color={mainTheme.palette.text.primary}
            fontSize={50}
            padding={0}
          >
            About Me
          </Typography>
        </Grid>
        <Grid
          sx={{
            backgroundColor: mainTheme.palette.background.infoBoxes,
            borderRadius: "20px",
            mt: "30px",
            padding: "30px"
          }}
          item
        >
          <Typography color="white">
            Fueled by a childhood fascination with software development, I'm a
            web development enthusiast currently studying Interactive Media at
            the University of Augsburg. My passion for building games and
            websites led me to learn JavaScript, HTML, React, and CSS. With
            experience in remote work and a knack for tackling diverse tasks
            while maintaining balance, I'm eager to contribute my skills to your
            team.
          </Typography>
        </Grid>
      </Grid>
  );
};

export default AboutMe;
