import {
  AppBar,
  Button,
  Stack,
  Toolbar,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import mainTheme from "../../theme/main-theme.tsx";

const NavBar = ({ handleButtonClick }) => {
  const isSmallScreen = useMediaQuery("(max-width:899px)");
  return (
    <Grid
      sx={{ padding: "20px", paddingTop: "0px" }}
      alignItems="center"
      container
    >
      {!isSmallScreen && (
        <Grid paddingLeft={1} md={6} item>
          <Typography variant="h1">
            TJM.<span className="techSpecial">tech</span>
          </Typography>
        </Grid>
      )}
      <Grid
        sx={{
          [mainTheme.breakpoints.down("md")]: {
            justifyContent: "center",
            alignItems: "center",
            padding: "30px"
          },
        }}
        xs={12}
        sm={12}
        md={6}
        lg={6}
        item
      >
        <AppBar
          sx={{
            [mainTheme.breakpoints.down("md")]: {
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              margin: "auto",
              width: "calc (100% - 40px)",
              position: "relative"
            },
            [mainTheme.breakpoints.down("sm")]: {
              position: "fixed",
              width: "100%"
            },
            bgcolor: mainTheme.palette.background.buttonBoxes,
            width: "min-content",
            boxSizing: "border-box",
            margin: "auto",
            backdropFilter: "blur(20px)",
          }}
          className="primaryAppBar"
        >
          <Toolbar>
            <Stack
              direction="row"
              spacing={1}
            >
              <Button
                sx={{
                  bgcolor: mainTheme.palette.background.button,
                  color: mainTheme.palette.text.primary,
                  borderRadius: "50px",
                  padding: "25px",
                  "&:hover": {
                    backgroundColor: mainTheme.palette.background.buttonHover,
                  },
                  [mainTheme.breakpoints.down("md")]: {
                    fontSize: "16px",
                  },
                }}
                className="menuButton"
                onClick={() => handleButtonClick("About")}
              >
                About
              </Button>
              <Button
                sx={{
                  bgcolor: mainTheme.palette.background.button,
                  color: mainTheme.palette.text.primary,
                  borderRadius: "50px",
                  padding: "25px",
                  "&:hover": {
                    backgroundColor: mainTheme.palette.background.buttonHover,
                  },
                }}
                className="menuButton"
                onClick={() => handleButtonClick("Work")}
              >
                Work
              </Button>
              <Button
                sx={{
                  bgcolor: mainTheme.palette.background.button,
                  color: mainTheme.palette.text.primary,
                  borderRadius: "50px",
                  padding: "25px",
                  "&:hover": {
                    backgroundColor: mainTheme.palette.background.buttonHover,
                  },
                }}
                className="menuButton"
                onClick={() => handleButtonClick("Projects")}
              >
                Projects
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>
      </Grid>
    </Grid>
  );
};

export default NavBar;
